import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'

export const TIME_SPENT_ON_PAGE_EVENT = 'time-spent-on-page'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {string} event.pageUuid
 * @param {number} event.timeSpentInMs
 * @param {string} event.scope
 * @param {string} event.tabState
 */
export function trackTimeSpentOnPage(
  Vue,
  { pageUuid, timeSpentInMs, tabState, scope },
) {
  Vue.prototype.$absmartly.track(TIME_SPENT_ON_PAGE_EVENT, {
    page_uuid: pageUuid,
    time_spent_in_ms: timeSpentInMs,
    tab_state: tabState,
    ...getWindowLocationValues(scope),
  })
}
