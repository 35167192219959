import Vue from 'vue'
import ABSmartlyTracking from 'chimera/all/plugins/tracking/absmartly'
import { EventBus } from 'chimera/all/plugins/eventbus'
import { TIME_SPENT_ON_PAGE_EVENT } from 'chimera/all/plugins/tracking/absmartly/events'

export default ({ env, store }) => {
  const options = {
    apiKey: env.abSmartlyAPIKey,
    deviceType: store.getters['context/get']('deviceType'),
    sessionId: store.getters['context/get']('sessionId'),
    visitorId: store.getters['context/get']('visitorId'),
    country: store.getters['context/get']('country'),
    language: store.getters['context/get']('language'),
    environment: process.env.environment,
    application: process.env.concept,
    onEvent: (context, eventName, data) => {
      onEventCallback(store, context, eventName, data)
    },
  }

  try {
    Vue.use(ABSmartlyTracking, options)
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, options)
  }
}

/**
 * @param {object} store
 * @param {object} context
 * @param {string} eventName
 * @param {object} data
 */
export function onEventCallback(store, context, eventName, data) {
  const isProduction = process.env.environment === 'production'
  if (!isProduction) {
    logEventInDevelopmentMode(eventName, data)
  }

  const eventsWeCareAbout = ['ready', 'refresh']
  if (eventsWeCareAbout.includes(eventName) && data.goals === undefined) {
    const experimentList = [
      ...(data.exposures || []),
      ...(data.experiments || []),
    ]

    store.commit('experiments/clearActiveExperiments')
    experimentList.forEach((experiment) => {
      store.commit('experiments/addExperiment', experiment)
    })
  }

  if (eventName === 'exposure') {
    onExposureEvent(store, data, context)

    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.table(data)
    }
  }
}

/**
 * @param {object} store
 * @param {object} data
 * @param {boolean} data.assigned
 * @param {string} data.name
 * @param {string|number} data.variant
 * @param {number} data.id
 * @param {boolean} data.eligible
 * @param {boolean} data.overridden
 * @param {object} context
 */
export async function onExposureEvent(
  store,
  { assigned, eligible, overridden, name, variant, id },
  context,
) {
  if ((!assigned || !eligible) && !overridden) {
    store.commit('experiments/removeQueuedForId', name)
    return
  }

  let iteration
  const exposedExperiment = context
    .data()
    .experiments.find((experiment) => experiment.id === id)
  if (exposedExperiment?.iteration) {
    iteration = exposedExperiment.iteration
  }

  const queuedExperiment = await store.getters['experiments/queuedById'](name)
  store.dispatch('experiments/processQueued', {
    ...queuedExperiment,
    variant: String(variant),
    abSmartlyId: id,
    iteration,
  })
}

const IGNORED_EVENT_TYPES = {
  goal: [TIME_SPENT_ON_PAGE_EVENT],
  publish: [TIME_SPENT_ON_PAGE_EVENT],
}

/**
 * I have added this filter, just to avoid the massive amount of logs from certain ABsmartly events.
 * We can perhaps add this as a feature to a "Devtool" to configure this based on developers preferences or something.
 *
 * @param {string} eventName
 * @param {object} data
 */
export function logEventInDevelopmentMode(eventName, data = {}) {
  const ignoredEvents = IGNORED_EVENT_TYPES[eventName] || []

  const shouldIgnore =
    eventName === 'goal'
      ? ignoredEvents.includes(data?.name)
      : eventName === 'publish' && Array.isArray(data?.goals)
        ? data.goals.some((goal) => ignoredEvents.includes(goal?.name))
        : false

  if (shouldIgnore) {
    return
  }

  // eslint-disable-next-line no-console
  console.info(`ABSmartly ${eventName}:`, { data })
}
