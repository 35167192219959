import * as gaEvents from './events/analytics'
import * as adsEvents from './events/ads'

/**
 * Our event handling for Google Analytics.
 *
 * @param Vue
 */
export function bindEvents(Vue) {
  // On page view event.
  Vue.$eventBus.onPageViewEvent((event) => {
    gaEvents.trackPageView(Vue, event)
  })

  // On click GA event.
  Vue.$eventBus.onClickEvent((event) => {
    gaEvents.trackClick(Vue, event)
  })

  // On visibility GA event.
  Vue.$eventBus.onVisibilityEvent((event) => {
    gaEvents.trackVisibility(Vue, event)
  })

  // On dialog open and close.
  Vue.$eventBus.onDialogEvent((event) => {
    // TODO: https://bambelo.atlassian.net/browse/PD-4715
  })

  // Javascript errors.
  Vue.$eventBus.onJsErrorEvent((event) => {
    gaEvents.trackAppError(Vue, event)
  })

  Vue.$eventBus.onLeadStoreUpdateEvent((event) => {
    adsEvents.setUserData(Vue, event)
  })

  // User input validation errors.
  Vue.$eventBus.onFormFieldErrorEvent((event) => {
    gaEvents.trackUserError(Vue, event)
  })

  // Lead completed.
  Vue.$eventBus.onTransactionEvent((event) => {
    gaEvents.trackLeadConversion(Vue, event)
    gaEvents.trackPurchase(Vue, event)

    // Do not track ads if gtagIds are not set.
    const { gAdsId, gAdsConversionAction } = Vue.$googleAds.options
    if (gAdsId && gAdsConversionAction) {
      adsEvents.trackConversion(Vue, event)
    }
  })

  Vue.$eventBus.onBeginCheckoutEvent((event) => {
    gaEvents.trackBeginCheckoutStep(Vue, event)
  })

  // When our postal autocomplete api cannot find a matching street and city to use.
  // Tracking these postal codes to improve our api.
  Vue.$eventBus.onPostalNotAutoCompletedEvent((event) => {
    gaEvents.trackPostalNotAutoCompleted(Vue, event)
  })

  // When our back-end doesn't know a postal code which could be valid.
  // Tracking these postal codes to improve our coverage.
  Vue.$eventBus.onPostalNotFoundEvent((event) => {
    gaEvents.trackPostalNotFound(Vue, event)
  })

  // Set custom dimension on service selection.
  Vue.$eventBus.onServiceSelectionEvent((event) => {
    gaEvents.trackServiceSelection(Vue, event)
    gaEvents.setServiceCustomDimension(Vue, event)
  })

  // Set custom dimension on experiment set.
  Vue.$eventBus.onExperimentSetEvent((event) => {
    gaEvents.setExperimentCustomDimension(Vue, event)
  })

  // Track non-transaction conversions.
  Vue.$eventBus.onConversionEvent((event) => {
    gaEvents.trackConversion(Vue, event)
  })

  // Track consent event.
  Vue.$eventBus.onConsentEvent((event) => {
    gaEvents.trackConsent(Vue, event)
  })
}
