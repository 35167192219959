import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'

export const FORM_NAVIGATION_EVENT = 'form-navigation'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.direction
 * @param {object} event.fromStepId
 * @param {object} event.toStepId
 * @param {object} event.scope
 */
export function trackFormNavigation(
  Vue,
  { direction, fromStepId, toStepId, scope },
) {
  Vue.prototype.$absmartly.track(FORM_NAVIGATION_EVENT, {
    direction,
    from_step_id: fromStepId,
    to_step_id: toStepId,
    form_id: scope.form_id,
    form_entry_point: scope.form_entry_point,
    ...getWindowLocationValues(scope),
  })
}
