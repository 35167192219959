import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'
import { convertObjectKeysToSnakeCase } from 'chimera/all/plugins/global.functions'

export const VISIBILITY_CHANGED_EVENT = 'visibility-changed'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {string} event.elementId
 * @param {object} event.metadata
 * @param {object} event.scope
 */
export function trackVisibility(Vue, { elementId, metadata, scope }) {
  const properties = {
    element_id: elementId,
    ...convertObjectKeysToSnakeCase(metadata),
    ...getWindowLocationValues(scope),
  }

  Vue.prototype.$absmartly.track(VISIBILITY_CHANGED_EVENT, properties)
}
