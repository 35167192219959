import HotjarTracking from 'chimera/all/plugins/tracking/hotjar'
import Vue from 'vue'

export default ({ env, store }) => {
  const options = {
    siteId: env.hotjarSiteId,
    sessionId: store.getters['context/get']('sessionId'),
    visitorId: store.getters['context/get']('visitorId'),
    country: store.getters['context/get']('country'),
    language: store.getters['context/get']('language'),
    label: env.concept,
    store,
  }

  Vue.use(HotjarTracking, options)
}
