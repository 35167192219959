import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

export const SURVEY_SERVICE = {
  HOTJAR: 'hotjar',
}

/**
 * @type {string}
 */
export const surveyEvent = 'eb_survey'

/**
 * @param {string} name
 * @param {string} service
 */
export function emitSurveyEvent(name, service = SURVEY_SERVICE.HOTJAR) {
  emitSurveyEventInScope(getEventScope(), name, service)
}

/**
 * @param {object} scope
 * @param {string} name
 * @param {string} service
 */
export function emitSurveyEventInScope(scope, name, service) {
  emit(surveyEvent, {
    service,
    name,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onSurveyEvent(callback) {
  bindOn(surveyEvent, callback)
}
