import Hotjar from '@hotjar/browser'
import { EventBus } from 'chimera/all/plugins/eventbus'

export const HOTJAR_READY_TIMEOUT = 3000

/**
 * @param {object} event
 * @param {object} event.experiment
 */
export function trackExperimentSetEvent({ experiment }) {
  const { variant, abSmartlyId, id } = experiment

  /**
   * If the experiment id is not set, we don't want to track it
   * This occurs when getting the variantValues from ABSmartly
   * PD-5294
   */
  if (id === undefined) {
    return
  }

  const ticketId = id.substring(0, id.indexOf(' | '))
  const variantAsLetter = String.fromCharCode(
    97 + parseInt(variant),
  ).toUpperCase()

  const eventName = `${ticketId}_${variantAsLetter}_${abSmartlyId}_assigned`

  Hotjar.event(eventName)
}

/**
 * @param {number} timeoutInMs
 * @returns {Promise<boolean>}
 */
export function isHotjarReady(timeoutInMs) {
  return Promise.race([
    new Promise((resolve) => {
      const check = () => {
        if (Hotjar && Hotjar.isReady()) {
          resolve(true)
        } else {
          setTimeout(check, 100)
        }
      }
      check()
    }),
    new Promise((resolve) => setTimeout(() => resolve(false), timeoutInMs)),
  ])
}

/**
 * @param {string} name
 */
export async function triggerSurvey(name) {
  const ready = await isHotjarReady(HOTJAR_READY_TIMEOUT)
  if (!ready) {
    const error = new Error(`Hotjar not ready after ${HOTJAR_READY_TIMEOUT}ms.`)
    EventBus.emitWarningAppErrorEvent(error, { name })
    return
  }

  Hotjar.event(name)
}
