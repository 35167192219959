import { SURVEY_SERVICE } from 'chimera/all/plugins/eventbus/events'
import { getEventNameForPagePath } from 'chimera/all/plugins/tracking/hotjar/helpers'
import { triggerSurvey } from './events'

/**
 * Our event handling for Hotjar Event.
 *
 * @param {object} Vue
 * @param {object} options
 * @param {Function} options.enqueueEvent
 */
export function bindEvents(Vue, { enqueueEvent }) {
  Vue.$eventBus.onExperimentSetEvent((event) => {
    // Temporarily disabled this event, as it is broken.
    // https://bambelo.atlassian.net/browse/PD-8041
    // enqueueEvent('onExperimentSetEvent', () => trackExperimentSetEvent(event))
  })

  Vue.$eventBus.onSurveyEvent(({ name, service }) => {
    if (service !== SURVEY_SERVICE.HOTJAR) {
      return
    }

    enqueueEvent('onSurveyEvent', () => triggerSurvey(name))
  })

  Vue.$eventBus.onPageViewEvent(({ scope }) => {
    const eventName = getEventNameForPagePath(scope)

    Vue.$eventBus.emitSurveyEvent(eventName, SURVEY_SERVICE.HOTJAR)
  })
}
