import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'

export const FORM_ENTER_EVENT = 'form-enter'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.toStepId
 * @param {object} event.scope
 */
export function trackFormEnter(Vue, { toStepId, scope }) {
  const properties = {
    to_step_id: toStepId,
    form_id: scope.form_id,
    form_entry_point: scope.form_entry_point,
    ...getWindowLocationValues(scope),
  }

  Vue.prototype.$absmartly.track(FORM_ENTER_EVENT, properties)
}
