<template>
  <div
    :id="id"
    class="t-selectable t-selectable--text-field"
    :class="[
      {
        't-selectable--selected': isSelected,
      },
      colorVariant,
    ]"
  >
    <div class="t-selectable__wrapper">
      <input
        :id="id + '-selectable'"
        :role="role"
        :type="type"
        class="t-selectable__input"
        :value="value"
      />

      <div class="t-selectable__icon" @click="onClick">
        <span class="t-selectable__icon-wrapper">
          <transition name="fade">
            <div
              :key="isSelected ? 'selected' : 'unselected'"
              class="flex absolute inset-0"
            >
              <t-icon :icon="isSelected ? selectedIcon : unselectedIcon" />
            </div>
          </transition>
        </span>
      </div>

      <label
        :for="id + '-selectable'"
        class="t-selectable__label"
        @click.prevent
      >
        <div v-if="label" class="t-selectable__text-container" @click="onClick">
          <span class="t-selectable__text">{{ label }}</span>
        </div>

        <div
          v-if="options && options.subtext"
          class="t-selectable__subtext-container"
        >
          <span class="t-selectable__subtext">{{ options.subtext }}</span>
        </div>

        <t-text-field
          ref="textField"
          :value="hasNoTextFieldValue ? undefined : innerValue"
          v-bind="{ ...textFieldAttributes }"
          class="t-selectable__text-field"
          @click="onTextFieldClick"
          @focus="onTextFieldFocus"
          @blur="onValueChange"
        />

        <div
          v-if="options && options.tooltip"
          class="t-selectable__tooltip-container"
        >
          <Tooltip class="t-selectable__tooltip" :content="options.tooltip" />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import AbstractTSelectable from 'chimera/all/components/elements/AbstractTSelectable'

export default {
  name: 'TSelectableWithTextField',

  extends: AbstractTSelectable,

  /**
   * @returns {{innerValue: string}}
   */
  data() {
    return {
      innerValue: this.value,
    }
  },

  computed: {
    /**
     * @returns {object}
     */
    textFieldAttributes() {
      const defaultAttributes = {
        prefix: '',
        placeholder: '',
        type: 'text',
      }

      return { ...defaultAttributes, ...this.options?.textField }
    },

    /**
     * Helper value to determine what the text field should display
     * If the value is the same as the placeholder or label, the text field should be empty
     *
     * @returns {boolean}
     */
    hasNoTextFieldValue() {
      return this.innerValue === this.defaultSelectionValue
    },

    /**
     * @returns {string}
     */
    defaultSelectionValue() {
      return this.label || this.textFieldAttributes.placeholder
    },
  },

  watch: {
    /**
     * @param {string} newValue
     */
    value(newValue) {
      this.innerValue = newValue
    },
  },

  methods: {
    /**
     */
    onTextFieldClick() {
      if (this.isCheckbox) {
        return
      }

      this.onClick()
    },

    /**
     */
    onTextFieldFocus() {
      if (this.isSelected) {
        return
      }

      this.emitSelectionEvent()
    },

    /**
     */
    async onClick() {
      this.emitSelectionEvent()

      await this.$nextTick()

      this.onValueChange(
        this.isSelected ? this.innerValue : this.defaultSelectionValue,
      )

      if (this.isSelected) {
        this.$refs.textField.focus()
      }
    },

    /**
     * @param {string} value
     */
    onValueChange(value) {
      this.emitValueEvent(value || this.defaultSelectionValue)
    },
  },
}
</script>
