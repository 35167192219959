import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

export * from './click'
export * from './consent'
export * from './conversion'
export * from './ecommerce'
export * from './errors'
export * from './experiment'
export * from './pageView'
export * from './postal'
export * from './service'
export * from './visibility'

/**
 * Returns a new object with page_path scope merged into the event.
 *
 * The documentation of Google Analytics on SPA applications explicitly mentions not to change
 * the value of page_location and tells us just to include page_path. This documentation overrides other
 * protocol/global gtag documentation found elsewhere.
 * Source: https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 *
 * @param {object} scope
 * @param {object} event
 * @returns {object}
 */
export function createScopedEvent(scope, event = {}) {
  const analyticsScope = {
    page_title: scope.title,
    page_location: scope.href,
  }

  return Object.assign({}, event, getEventScope(), analyticsScope)
}
