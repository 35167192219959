export const NEXT_PAGE_AFTER_ERROR_EVENT = 'next-page-after-error'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {boolean} event.hadErrors
 */
export function trackNextPageAfterError(Vue, { hadErrors }) {
  if (!hadErrors) {
    return
  }

  Vue.prototype.$absmartly.track(NEXT_PAGE_AFTER_ERROR_EVENT)
}
