import Vue from 'vue'
import MetaTracking from 'chimera/all/plugins/tracking/meta'
import { EventBus } from 'chimera/all/plugins/eventbus'

export default ({ env, store }) => {
  Vue.use(MetaTracking, {
    metaServerSideEventsApi: env.metaServerSideEventsApi,
    pixels: getPixels(env),
    store,
  })
}

/**
 * Get all defined fb pixels
 *
 * @param {object} env
 * @returns {Array<{id: string, organisation: string}>}
 */
export function getPixels(env) {
  const pixelConfigs = [
    {
      name: 'metaRollupPixel',
      value: env.metaRollupPixel,
      organisation: 'Bambelo',
    },
    {
      name: 'metaRollupId',
      value: env.metaRollupId,
      organisation: 'Yonego',
    },
  ]

  return pixelConfigs.reduce((pixels, { name, value, organisation }) => {
    if (!value) {
      EventBus.emitWarningAppErrorEvent(
        new Error(`Required Meta Pixel ${name} has not been defined.`),
        {
          name,
          value,
          required: true,
        },
      )
    } else {
      pixels.push({ id: value, organisation })
    }
    return pixels
  }, [])
}
