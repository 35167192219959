import { getWindowLocationValues } from 'chimera/all/plugins/tracking/absmartly/helpers'
import { convertObjectKeysToSnakeCase } from 'chimera/all/plugins/global.functions'

export const CLICK_EVENT = 'click'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {string} event.elementId
 * @param {string} event.elementType
 * @param {object} event.metadata
 * @param {object} event.scope
 */
export function trackClick(Vue, { elementId, elementType, metadata, scope }) {
  const properties = {
    element_id: elementId,
    element_type: elementType,
    ...convertObjectKeysToSnakeCase(metadata),
    ...getWindowLocationValues(scope),
  }

  Vue.prototype.$absmartly.track(CLICK_EVENT, properties)
}
