export const FORM_SUBMIT_ERROR_EVENT = 'form-submit-error'

/**
 * When a field is validated and invalid we track the error, so we can analyse
 * the amount of invalid inputs and what errors occur most.
 *
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @example {
 *   scope: scopeObject
 * }
 */
export function trackFormSubmitError(Vue, { scope } = {}) {
  Vue.prototype.$absmartly.track(FORM_SUBMIT_ERROR_EVENT, scope)
}
