import { EventBus } from 'chimera/all/plugins/eventbus'
import { ConsentLevel } from 'chimera/all/plugins/eventbus/events'

const CONSENT_STATUS = 'consent_status'

let options = {
  id: null,
}

export const ServiceIds = {
  USERCENTRICS_CMP: 'H1Vl5NidjWX',
  AB_SMARTLY: 'yDs2tC_qtLDpOR',
  DOUBLECLICK_AD: '9V8bg4D63',
  FACEBOOK_PIXEL: 'ko1w5PpFl',
  FACEBOOK_SOCIAL_PLUGIN: 'XYQZBUojc',
  GA_AUDIENCES: 'FRfzEaKQ2Fm4DR',
  GOOGLE_ADS: 'S1_9Vsuj-Q',
  GOOGLE_ANALYTICS: 'HkocEodjb7',
  GOOGLE_TAG_MANAGER: 'BJ59EidsWQ',
  GOOGLE_FONTS: 'HkPBYFofN',
  HOTJAR: 'S1kgcNo_j-m',
  HUBSPOT: 'ry0QcNodoWQ',
  LINKEDIN_ADS: 'rk-nqEj_o-m',
  LINKEDIN_INSIGHT_TAG: 'JQ2XQxIk',
  LINKEDIN_PLUGIN: 'Hkx754i_iWm',
  MICROSOFT_ADVERTISING_REMARKETING: 'dsS7z9Hv4',
  SENTRY: 'rH1vNPCFR',
}

export default async ({ env }) => {
  options = {
    id: env.cookieConfigurationId,
  }

  if (!options.id) {
    EventBus.emitErrorAppErrorEvent(new Error('Usercentrics ID is missing'))
    return
  }

  installUsercentricsScript(options.id)

  await initializeCookiebar()
}

/**
 * Initialize cookie bar based on experiment
 */
export const initializeCookiebar = () => {
  window.addEventListener('ucEvent', (event) => {
    switch (event.detail.action) {
      case 'onInitialPageLoad':
        if (event.detail.event === CONSENT_STATUS) {
          window.addEventListener('UC_UI_INITIALIZED', () => {
            handleConsentEvent()
          })
        }
        break
      case 'onAcceptAllServices':
      case 'onUpdateServices':
        handleConsentEvent()
        break
    }
  })
}

/**
 * Initialize plugin with consent based on version
 * @param {Function} initializationFn
 * @param {Array} requiredServiceIds
 */
export function initializePluginWithConsent(
  initializationFn,
  requiredServiceIds = [],
) {
  if (!options.id) {
    initializationFn()
    return
  }

  EventBus.onConsentEvent(async (event) => {
    const consentGiven = await hasConsentForServices(requiredServiceIds)
    if (event.level === ConsentLevel.YES || consentGiven) {
      initializationFn()
    }
  })
}

export const handleConsentEvent = async () => {
  try {
    const consentLevelMap = {
      ALL_ACCEPTED: ConsentLevel.YES,
      SOME_ACCEPTED: ConsentLevel.PARTIALLY_YES,
      ALL_DENIED: ConsentLevel.NO,
    }

    const services = await getConsentDetails()
    const status = services?.consent?.status
    const level = consentLevelMap[status] || ConsentLevel.NO

    EventBus.emitConsentEvent({
      level,
      services,
    })
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Failed to handle consent event'),
      'handleConsentEvent',
    )
  }
}

/**
 * Waits for the Usercentrics CMP to initialize, if already initialized, resolves immediately.
 *
 * @returns {Promise<boolean>}
 */
function waitForUcCmp() {
  if (window.__ucCmp?.isInitialized()) {
    return Promise.resolve(true)
  }

  return new Promise((resolve) => {
    const timeout = setTimeout(() => {
      window.removeEventListener('ucEvent', handleEvent)
      resolve(false)
    }, 2000)

    const handleEvent = () => {
      clearTimeout(timeout)
      resolve(window.__ucCmp.isInitialized())
    }

    window.addEventListener('ucEvent', handleEvent, { once: true })
  })
}

/**
 * @param {string[]} requiredServiceIds
 * @returns {Promise<boolean>}
 */
export async function hasConsentForServices(requiredServiceIds) {
  if (!(await waitForUcCmp()) || !window.__ucCmp) {
    return false
  }

  try {
    const { services } = await getConsentDetails()
    return requiredServiceIds.every((id) => services?.[id]?.consent?.given)
  } catch (err) {
    return false
  }
}

/**
 * @returns {Promise<[]>}
 */
function getConsentDetails() {
  return runUsercentricsFunction(
    'getConsentDetails',
    'Unable to retrieve consent details',
  )
}

/**
 */
export function openSecondLayer() {
  runUsercentricsFunction('showSecondLayer', 'Unable to show second layer')
}

/**
 * TODO: PD-6757 Implement this function
 */
export function showEmbeddings() {
  EventBus.emitErrorAppErrorEvent(
    new Error('ShowEmbedding not yet implemented. PD-6757'),
    {},
  )
}

/**
 * Runs a Usercentrics function and handles errors.
 * @param {string} functionName
 * @param {string} errorMessage
 * @returns {void|Promise<any>}
 */
async function runUsercentricsFunction(functionName, errorMessage) {
  const ucCmp = window.__ucCmp

  if (typeof ucCmp?.[functionName] !== 'function') {
    const error = new Error(
      `${functionName} is not a valid function on Usercentrics.`,
    )
    EventBus.emitErrorAppErrorEvent(error, functionName)
    return null
  }

  try {
    return await ucCmp[functionName]()
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, functionName)
    return null
  }
}

/**
 * Installs the Usercentrics script.
 * @param {string} usercentricsId
 */
export function installUsercentricsScript(usercentricsId) {
  if (!usercentricsId) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Usercentrics ID is not provided'),
      'installUsercentricsScript',
    )
    return
  }

  if (!document.getElementById('usercentrics-cmp')) {
    const usercentricsScript = document.createElement('script')
    usercentricsScript.id = 'usercentrics-cmp'
    usercentricsScript.src = '//web.cmp.usercentrics.eu/ui/loader.js'
    usercentricsScript.setAttribute('data-settings-id', usercentricsId)
    usercentricsScript.async = true
    document.head.appendChild(usercentricsScript)
  }
}
