var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t-text-field",class:[
    { 't-text-field--show-icons': !_vm.hideIcons },
    { 't-text-field--dense': _vm.dense },
    { 't-text-field--focused': _vm.isFocused },
    _vm.validity,
    _vm.colorVariant,
  ]},[(_vm.label)?_c('label',{staticClass:"t-text-field__label",attrs:{"for":_vm.$attrs.id}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"t-text-field__wrapper"},[_vm._t("default",function(){return [(_vm.prefix)?_c('div',{staticClass:"t-text-field__prefix"},[_vm._v("\n        "+_vm._s(_vm.prefix)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.prefixIcon)?_c('div',{staticClass:"t-text-field__prefix-icon"},[_c('t-icon',{attrs:{"icon":_vm.prefixIcon}})],1):_vm._e(),_vm._v(" "),_c('input',_vm._b({ref:"input",staticClass:"t-text-field__input",attrs:{"placeholder":_vm.placeholder || _vm.$attrs.placeholder},on:{"input":_vm.onInput,"blur":_vm.onBlur,"focus":_vm.onFocus,"click":_vm.onClick}},'input',{ ..._vm.$props, ..._vm.$attrs },false)),_vm._v(" "),_c('span',{staticClass:"t-text_field__state-icon"})]})],2),_vm._v(" "),_c('FormErrorMessages',{attrs:{"error-messages":_vm.errorMessages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }