import absmartly from '@absmartly/vue2-sdk'
import { bindEvents } from './bind'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * Documentation:
 * https://www.npmjs.com/package/@absmartly/vue2-sdk
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  const {
    apiKey,
    country,
    language,
    deviceType,
    sessionId,
    visitorId,
    environment,
    application,
    onEvent,
  } = (options = { ...options, ..._options })

  Vue.use(absmartly.ABSmartlyVue, {
    sdkOptions: {
      endpoint: 'https://bambelo.absmartly.io/v1',
      apiKey,
      environment,
      application,
      eventLogger: onEvent,
    },
    context: {
      units: {
        session_id: sessionId,
        visitor_id: visitorId,
      },
    },
    attributes: {
      user_agent: navigator.userAgent,
      device_type: deviceType,
      country,
      language,
      label: application,
    },
  })

  bindEvents(Vue)
}
