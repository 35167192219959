module.exports = {
  global: {
    'contact-us': 'Contact us',
    back: 'Back',
    continue: 'Continue',
    privacyPolicy: 'Privacy policy',
    privacyPolicyLink: '/privacy',
    cookiePolicy: 'Cookies',
    cookiePolicyLink: '/cookies',
    termsAndConditions: 'Terms & conditions',
    termsAndConditionsLink: '/terms-and-conditions',
    imprint: '@todo',
    imprintLink: '@todo',
    companyLink: 'https://www.bambelo.com/en/',
    receiveUpToSixQuotes:
      'Get up to 6 quotes from professionals in your area and compare.',
    requiredFields: 'Required fields',
    other: 'Other',
    yes: 'Yes',
    no: 'No',
  },

  validation: {
    required: 'Are you not forgetting this one?',
    invalid: 'Something seems to have gone wrong.',
    blacklisted: 'Something seems to have gone wrong.',
    invalidLength: "The amount of characters doesn't seem to be correct.",
  },

  field: {
    marketSegment: {
      consumer: 'Consumer',
      corporate: 'Corporate',
      corporateValue: 'Corporate',
    },

    postal: {
      label: 'Zipcode',
      placeholder: 'Zipcode',
      validation: {
        invalid: "Your zip code doesn't seem valid.",
        required: 'You forgot to enter your zip code.',
        unknown: 'Your zip code is unknown to us.',
        incorrectFormat: '@todo',
      },
    },

    comments: {
      label: 'Comments',
      placeholder: 'Comments',
      validation: {
        invalid: '@todo',
        required: 'Do you really want to tell our experts nothing?',
      },
    },

    'address-city': {
      label: 'City',
      placeholder: 'City',
      validation: {
        invalid: "Your place of residence doesn't seem to be correct.",
        required: 'You forgot to enter your city.',
      },
    },

    'address-country-name': {
      label: 'Country',
      placeholder: 'Country',
      validation: {
        invalid: "Your country name doesn't seem to be correct.",
        required: 'You forgot to enter your country name.',
      },
    },

    'address-floor-level': {
      label: 'Floor',
      placeholder: 'Floor',
      validation: {
        invalid: "Your floor level doesn't seem to be correct.",
        required: 'You forgot to enter your floor level.',
      },
    },

    'address-number': {
      label: 'House number',
      placeholder: 'House number',
      validation: {
        invalid: "Your house number doesn't seem to be correct.",
        required: 'You forgot to enter your house number.',
      },
    },

    'address-property-type': {
      label: 'Property type',
      placeholder: 'Property type',
      validation: {
        invalid: "Your type of home doesn't seem to be correct.",
        required: 'You forgot to enter your property type.',
      },
    },

    'address-street': {
      label: 'Address',
      placeholder: 'Address',
      validation: {
        invalid: "Your street name doesn't seem to be correct.",
        required: 'You forgot to enter your street name.',
      },
    },

    'company-name': {
      label: 'Company name',
      placeholder: 'Company name',
      validation: {
        invalid: "This company name doesn't seem to be correct.",
        required: 'You forgot to enter your company name.',
      },
    },

    'dormer-width': {
      label: 'Width',
      placeholder: 'Width',
      validation: {
        invalid: "This size doesn't seem to be correct.",
        required: 'You forgot to enter the size.',
      },
    },

    email: {
      label: 'E-mail',
      placeholder: 'E-mail',
      validation: {
        invalid: "Your e-mail doesn't seem to be correct.",
        required: 'You forgot to enter your e-mail.',
      },
    },

    name: {
      label: 'Name',
      placeholder: 'Name',
      validation: {
        invalid: "Your name doesn't seem to be correct.",
        required: 'You forgot to enter your name.',
      },
    },

    phone: {
      label: 'Phone number',
      placeholder: 'Phone number',
      validation: {
        invalid: "Your phone number doesn't seem to be correct.",
        required: 'You forgot to enter your phone number.',
      },
    },

    message: {
      label: 'Message',
      placeholder: 'Message',
      validation: {
        invalid: '@todo',
        required: 'You forgot to enter your message.',
      },
    },

    'contact-name': {
      label: 'Contact name',
      placeholder: 'Contact name',
      validation: {
        invalid: '@todo',
        required: "You forgot to enter the contact's name.",
      },
    },

    'estimated-garden-area': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
    },

    'move-preferred-date': {
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
      flexibleDate: '@todo',
      specificDate: '@todo',
    },

    'period-of-construction': {
      options: {
        'before-1975': '@todo',
        'between-1975-and-1992': '@todo',
        'after-1992': '@todo',
        'between-1975-and-1990': '@todo',
        'between-1990-and-2000': '@todo',
        'after-2000': '@todo',
      },
    },

    'type-of-heating': {
      options: {
        'floor-heating': '@todo',
        'floor-heating-and-radiators': '@todo',
        radiators: '@todo',
      },
    },

    'type-of-heat-pump': {
      options: {
        'air-air-heat-pump': '@todo',
        'air-water-heat-pump': '@todo',
        'ground-water-heat-pump': '@todo',
        'water-water-heat-pump': '@todo',
        'hybrid-heat-pump': '@todo',
        unknown: '@todo',
      },
    },

    'type-of-insulation': {
      options: {
        'roof-insulation': '@todo',
        'cavity-wall-insulation': '@todo',
        'floor-insulation': '@todo',
        'double-glazing': '@todo',
        'no-insulation': '@todo',
        'facade-or-external-wall-insulation': '@todo',
      },
    },

    'type-of-existing-radiator': {
      options: {
        'low-temperature-radiator': '@todo',
        'regular-radiator': '@todo',
        'low-temperature-radiator-description': '@todo',
        'house-battery-and-solar-panels': '@todo',
        'estimated-annual-electricity-consumption': '@todo',
      },
    },

    'intends-to-replace-radiator': {
      options: {
        description: '@todo',
      },
    },

    'type-of-work': {
      bathroom: {
        options: {
          'new-construction': '@todo',
          renovation: '@todo',
          'install-sanitary': '@todo',
          other: '@todo',
        },
      },

      sunscreens: {
        options: {
          'mounting-installing': 'Mounting / Installation',
          repair: 'Repair',
        },
      },
    },

    'sanitary-facilities': {
      options: {
        'shower-cabin': '@todo',
        'bath-tub': '@todo',
        washbasin: '@todo',
        faucets: '@todo',
        toilet: '@todo',
        radiator: '@todo',
        'bathroom-furniture': '@todo',
        'other-sanitary-facilities': '@todo',
      },
    },

    'bathroom-size': {
      options: {
        '1-to-5': '@todo',
        '5-to-10': '@todo',
        '10-to-15': '@todo',
        '15-to-20': '@todo',
        '20-to-25': '@todo',
        '25-to-30': '@todo',
        'more-than-30': '@todo',
      },
    },

    'materials-present': {
      options: {
        'all-purchased': '@todo',
        'partially-purchased': '@todo',
        'not-purchased': '@todo',
      },
    },

    'removal-required': {
      options: {
        'yes-all': '@todo',
        no: '@todo',
        'in-consultation': '@todo',
      },
    },

    'execution-period': {
      options: {
        'within-1-month': '@todo',
        '1-to-3-months': '@todo',
        '3-to-6-months': '@todo',
        'in-consultation': '@todo',
      },
    },

    floor: {
      options: {
        groundFloor: 'Ground floor',
        firstFloor: 'First floor',
        secondFloor: 'Second floor',
        higherThanSecondFloor: 'Higher than the second floor',
      },
    },

    'control-method': {
      options: {
        manual: '@todo',
        electric: '@todo',
        automatic: '@todo',
        advise: '@todo',
      },
    },

    'application-type': '@todo',
    'business-premises': '@todo',
    'agricultural-enterprise': '@todo',
    'large-business-requests': '@todo',
    'residential-building': '@todo',
    'type-of-assignment': '@todo',
    'house-battery': '@todo',
    'house-battery-and-solar-panels': '@todo',
    'estimated-annual-electricity-consumption': '@todo',
    'data-incomplete-error-message': 'There are some details missing',
  },

  'phone-verification': {
    title: "Almost there! You're one step away from getting your quotes.",
    instructions:
      "To confirm your phone is correct, we'll send a verification code to",
    'send-code': 'Send verification code',
    'modify-number': 'Modify number',
  },

  'phone-validation': {
    title: "Almost there! You're one step away from getting your quotes.",
    subtitle:
      'With a correct phone number, professionals can contact you more easily.',
    instructions: 'Enter the code we sent to your phone',
    'send-sms': 'Resend SMS',
    'edit-number': 'Modify number',
    validate: 'Validate phone',
    'verify-result': {
      'error-message': 'Invalid code: please try again.',
    },
    'result-codes': {
      'sms-is-not-supported': 'Landline phone numbers do not support SMS',
      'max-attempts-reached': 'You have reached the maximum of 5 attempts.',
      'rate-limit-triggered':
        'You have made too many requests in a short time; please try again later.',
      'too-many-concurrent-requests':
        'Too many concurrent phone number requests',
    },
  },

  steps: {
    typeOfAssignment: 'Type of assignment',
  },

  privacyPolicy: {
    title: 'Privacy policy',
    headDescription: 'Privacy policy for website visitors',
    introduction:
      '{concept} is committed to protecting your privacy. That is why we want to be open and transparent about the processing of your personal data. This privacy policy therefore contains information about how your personal data is processed and protected. This privacy policy applies to the use of the website and the services of {concept} accessible via the website.',

    section1Title: 'Who processes your personal data?',
    section1Body1:
      'Dutch company Bambelo B.V. processes the personal data you provide to us through one of our websites and is responsible for your personal data under applicable data protection law. Our contact details are as follows:',
    companyAddressStreet: 'Prinsenkade 5B',
    companyZipcode: 'NL-4811 VB',
    companyAddressCity: 'Breda Netherlands',
    companyChamberOfCommerce: 'Chamber of Commerce no.: 20111350',
    section1Body2:
      'If you use one of our websites to request quotations, we will pass on your data to our affiliated partners who meet the criteria you have specified. They will process your data in accordance with their own privacy policy.  Based on this information, these companies will contact you by email or by telephone to inform you about their services and rates. A single request via {concept} will therefore lead to data storage at {concept} itself and at the companies linked to your request. Based on legislation and regulations and an agreement as concluded between Bambelo B.V. and the organisations to which Bambelo provides your request, the organisations that receive your data must also comply with the provisions of the agreement concluded with them and the General Data Protection Regulation (GDPR). The contact details for these parties can be found in the quotations you receive from them. If a company decides not to respond to the quotation request, that company will be obliged to destroy the data received.\n',

    section2Title: 'Where do we store your data?',
    section2Body1:
      'In principle, the data we collect from you will be stored within the European Economic Area (“EEA”). If data is transferred by us to and processed in a country outside the EEA, this will take place in accordance with the applicable laws, usually on the basis of standard contractual clauses and, where necessary, additional measures.',

    section3Title:
      'What types of personal data do we process and on what basis?',
    section3Body1: 'We process the following categories of personal data:',
    section3List1Item1:
      'Contact details, such as name, address, email address and telephone number',
    section3List1Item2:
      'Additional information relevant to the request, including:',
    section3List1Item2Sub1: 'Category',
    section3List1Item2Sub2: 'Type of request',
    section3List1Item2Sub3: 'Price range',
    section3List1Item2Sub4: 'Type of home',
    section3List1Item2Sub5: 'Other information that you provide yourself',
    section3Body2:
      'We rely on the following legal bases for processing the above data:',
    section3List2Item1:
      'If you request quotations via our website: for the performance of an agreement concluded with you, or on the basis of your consent',
    section3List2Item2:
      'If you ask a question (via our website): based on your consent',
    section3List2Item3:
      'In other cases: for the promotion of legitimate interests that we have in the context of our business operations, such as customer relationship management, improving our services, securing and keeping the website accessible, and maintaining contact with our clients. In doing so, we always weigh these interests against the interest of your privacy and only process the data necessary to achieve the defined objectives.',

    section4Title: 'How long do we retain your data?',
    section4Body:
      'We will retain your data for a maximum period of 7 years. We do this firstly in order to comply with our statutory retention period, and secondly to observe trends and improve our services. If you indicate that you no longer wish to use our services or wish to have the data removed, we will assess whether this retention period can be shortened and which data we can delete.',

    section5Title: 'Who has access to your data?',
    section5Body:
      'The personal data you provide will be used to ensure the proper functioning of the services offered by {concept}. {concept} only provides the necessary personal data to the affiliated partners to provide you with an appropriate offer based on your request.',

    section6Title: 'What do we use your personal data for?',
    section6Body:
      'We use your data first and foremost to request quotations for your desired product or service. We also use the data to evaluate, develop and improve our services, products and systems. This includes an analysis to make our services more user-friendly, such as changing the user interface to simplify the flow of information or to highlight frequently used features and to improve IT systems in order to enhance security. We will not analyse your data on an individual level for this purpose. We also use the data to develop new services and to improve our product range. The provision (and processing) of this data is a necessary condition for enabling the performance of the agreement or the execution of your request to receive quotations. If you do not provide the data, we will not be able to provide you with an effective service.',

    section7Title: 'What are your rights?',
    section7Right1Title: 'Right of access:',
    section7Right1:
      '{title} you have the right to request information about the personal data we hold about you at any time.',
    section7Right2Title: 'Right to portability:',
    section7Right2:
      '{title} where {concept} processes your personal data automatically on the basis of your consent or on the basis of an agreement, you have the right to have a copy of your data in a structured, commonly used and machine-readable format transmitted to you or to another party. This only relates to the personal data you have provided to us.',
    section7Right3Title: 'Right to rectification:',
    section7Right3:
      '{title} you have the right to request the rectification of your personal data if it is incorrect, including the right to have incomplete personal data completed.',
    section7Right4Title: 'Right to be forgotten:',
    section7Right4:
      '{title} you have the right to ask us to erase the personal data processed by {concept}.',
    section7Right5Title:
      'Right to restriction of processing and right to object to processing:',
    section7Right5:
      '{title} you can ask to restrict the processing of data, temporarily or otherwise, for example in order to verify the accuracy of the data. You also have the right to object to the processing of your personal data. If you object to processing for direct marketing purposes, the personal data will no longer be processed for these purposes.',
    section7Right6Title: 'Right to withdraw consent:',
    section7Right6:
      '{title} in so far as we process data on the basis of your consent, you have the right to withdraw this consent at any time. This does not prejudice the lawfulness of processing prior to the withdrawal.',
    section7Body:
      'For all of the above requests, you will receive a response to your request within 1 month. Depending on the complexity of the requests and the number of requests, this period may be extended by another two months if necessary, in which case we will inform you within one month of receipt of your request.',

    section8Title: 'How can you exercise your rights?',
    section8Body:
      'We take data protection very seriously. That is why we have a dedicated customer service team that will handle your questions regarding your aforementioned rights and to which you can submit a complaint. You can reach our team at any time at {email}.',

    section9Title: 'Cooperation in tax and criminal investigations',
    section9Body:
      'Where applicable, we may be required on the basis of a statutory obligation to share your data in connection with tax or criminal investigations. In such a case, we will be forced to share your data, but we will resist this within the possibilities offered to us by law.',

    section10Title: 'Data protection officer',
    section10Body:
      'We have appointed a Data Protection Officer (DPO) to ensure that we process your personal data in an open, accurate and legal manner at all times. You can contact our DPO by emailing {email}, stating DPO in the subject line.',

    section11Title:
      'The right to lodge a complaint with a supervisory authority',
    section11Body:
      'If you believe that {concept} is not processing your personal data correctly, please contact us. You also have the right to lodge a complaint with the Dutch Data Protection Authority against this processing of personal data. To do this, contact the Dutch Data Protection Authority via this {link}',
    section11BodyLinkText: 'link.',
    section11BodyLink:
      'https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap',

    section12Title: 'Updates to our privacy policy',
    section12Body:
      'Our privacy policy may occasionally need to be updated. The latest version of the privacy policy is always available on our website. We will give notice of any material changes to the privacy policy, for example with regard to the purpose of the use of your personal data, the identity of the Controller or your rights.',

    version: 'Version 4.1 – 23 February 2022',
  },

  cookiePolicy: {
    headTitle: 'Cookie policy',
    title: 'Cookies',
    whatIsCookies: {
      title: 'What is cookies?',
      body: 'Cookies are small pieces of information that are stored by your browser on your computer when you visit our website and can be retrieved on subsequent visits. We use cookies on this website.',
    },
    whatIsCookieFunction: {
      title: 'What is the function of these cookies?',
      firstParagraph:
        'We use cookies to ensure that you do not repeatedly receive or have to enter the same information when visiting our website. There are different types of cookies. In attachment you will find an overview of all cookies used that are displayed, who places the cookies, the name of the cookie, the purpose of placing the cookie, the period that the cookie is stored and the consequences of not accepting the cookie.',
      secondParagraph:
        'You can disable the storage of cookies on your computer via your browser. You can also be notified when you receive a cookie. Instructions regarding the adjustment of the browser settings can be found under ‘help’ in the toolbar of most browsers. Although you as a visitor of the site can still visit the site, it is possible that if cookies are refused, certain services or elements of the site will not function optimally. Disabling cookies limits the use of our site and services.',
      thirdParagraph:
        'Some tracking cookies are placed by third parties who, among other things, show you advertisements via our website. You can delete these cookies centrally via {link} so that they are not placed back on a third party website.',
      url: 'https://www.youronlinechoices.com/uk/your-ad-choices',
    },
    whoIAm: {
      title: 'Do cookies know who I am?',
      body: 'No. A cookie does not know your name, address, age or other personal data. They only remember your preferences and your interest(s) based on your browsing behaviour.',
    },
    listTitle: 'List of cookies',
    adjustPreferences: 'Adjust cookie preferences',
    version: 'Version',
  },

  termsAndConditions: {
    title: 'Terms & conditions',
    headDescription: 'Terms & conditions',
  },
}
