import {
  hasConsentForServices,
  initializePluginWithConsent,
  ServiceIds,
} from 'chimera/all/plugins/config/usercentrics.client'
import Hotjar from '@hotjar/browser'
import { EventBus } from 'chimera/all/plugins/eventbus'
import { bindEvents } from './bind'

const HOTJAR_VERSION = 6

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * Documentation: https://help.hotjar.com/hc/en-us/articles/115009336727
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  options = { ...options, ..._options }
  const { siteId, store, country, language, sessionId, visitorId, label } =
    options

  const requiredServices = [ServiceIds.HOTJAR]

  const QUEUE_EVENT_KEY = 'hotjar'
  const enqueueEvent = async (eventName, fn) =>
    (await hasConsentForServices(requiredServices))
      ? fn()
      : store.dispatch('queue/enqueue', {
          key: QUEUE_EVENT_KEY,
          eventName,
          func: fn,
        })

  initializePluginWithConsent(() => {
    Hotjar.init(siteId, HOTJAR_VERSION)

    // If any of the variables are undefined, Hotjar will not track the user
    if (!visitorId || !country || !language || !label || !sessionId) {
      EventBus.emitWarningAppErrorEvent(
        new Error('Could not add Hotjar user attribution'),
        {
          visitorId,
          country,
          language,
          label,
          sessionId,
        },
      )
    }

    Hotjar.identify(visitorId, {
      country,
      language,
      label,
      sessionId,
    })

    store.dispatch('queue/flush', QUEUE_EVENT_KEY)
  }, requiredServices)

  bindEvents(Vue, { enqueueEvent })
}
