/**
 * @param {object} scope
 *
 * @returns {string}
 */
export function getEventNameForPagePath(scope) {
  const rawPath = scope.pathname || '/'
  if (rawPath === '/') {
    return 'page-default'
  }

  const cleanedPath = rawPath.replace(/^\/+|\/+$/g, '').replace(/\/+/g, '-')
  return `page-${cleanedPath}`
}
