export const PAGE_VIEW_EVENT = 'page-view'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 */
export function trackPageView(Vue, { scope }) {
  Vue.prototype.$absmartly.track(PAGE_VIEW_EVENT, scope)
}
